<template>
  <div class="flex items-center justify-center text-f-white bo:text-f-tertiary flex-col md:flex-row">
    <div v-if="dropdowns.venue.show" class="w-full mx-2 mb-4 md:mb-0 md:relative" :class="fieldWidth">
      <div
        class="hj:bg-f-tertiary bg-f-tertiary/[.07] bo:bg-white rounded-xl pt-2 pb-2 border border-bg-f-tertiary/[.07] hj:border-f-tertiary hover:border-f-contrast hover:hj:border-white">
        <p class="text-xs mb-2.5 px-6">Location</p>
        <div
          ref="venueField"
          class="flex items-center justify-start px-6 hover:cursor-pointer"
          @click="toggleDropDown('venue')">
          <img v-if="darkMode" :src="asset('assets/location-pin.svg')" alt="Location pin icon" class="w-4 mr-3" />
          <img v-else :src="asset('assets/location-pin-dark.svg')" alt="Location pin icon" class="w-4 mr-3" />
          <p>{{ searchParams.venue.name }}</p>
        </div>
      </div>
      <div
        v-if="dropdowns.venue.open === true"
        ref="venueDropdown"
        class=""
        :class="{
          'w-full h-auto absolute top-full left-0 mt-3 bg-f-white bo:bg-white hj:bg-f-tertiary border hj:border-white/50 md:border-f-bg/60 rounded-2xl pt-2 pb-3 md:px-0 z-50':
            isWidget,
          'w-full h-full md:h-auto fixed md:absolute top-0 md:top-full left-0 md:mt-3 bg-f-white bo:bg-white hj:bg-f-tertiary md:border md:hj:border-white/50 md:md:border-f-bg/60 md:rounded-2xl pt-10 md:pt-2 pb-3 px-6 md:px-0 z-50':
            notIsWidget,
        }">
        <div class="flex items-start justify-between mb-4" :class="{ hidden: isWidget, 'md:hidden': notIsWidget }">
          <p class="bo:font-graphik-semibold hj:font-druk-heavy-italic text-lg">Which venue?</p>
          <img
            v-if="darkMode || $page.props.brand.code === 'hj'"
            :src="asset('assets/close-white.svg')"
            alt="close icon"
            class="w-4 cursor-pointer"
            @click="dropdowns.venue.open = false" />
          <img
            v-else
            :src="asset('assets/close-dark.svg')"
            alt="close icon"
            class="w-4 cursor-pointer"
            @click="dropdowns.venue.open = false" />
        </div>
        <div
          v-for="venue in venues"
          :key="venue.id"
          class="flex items-center justify-start py-3 md:px-6 text-f-bg hover:bg-f-contrast hover:text-f-white bo:text-f-tertiary hover:bo:bg-f-tertiary/[.07] hover:cursor-pointer"
          @click="selectVenue(venue.id)">
          <p>{{ venue.name }}</p>
        </div>
      </div>
    </div>
    <div v-if="dropdowns.guests.show" class="w-full mx-2 mb-4 md:mb-0 relative" :class="fieldWidth">
      <div
        class="hj:bg-f-tertiary bg-f-tertiary/[.07] rounded-xl pt-2 pb-2 border border-bg-f-tertiary/[.07] bo:bg-white hj:border-f-tertiary hover:border-f-contrast hover:hj:border-white">
        <p class="text-xs mb-2.5 px-6">Guests</p>
        <div
          ref="guestField"
          class="flex items-center justify-start px-6 hover:cursor-pointer"
          @click="toggleDropDown('guests')">
          <img v-if="darkMode" :src="asset('assets/user.svg')" alt="User icon" class="w-4 mr-3" />
          <img v-else :src="asset('assets/user-dark.svg')" alt="User icon" class="w-4 mr-3" />
          <p>{{ searchParams.guests }} guests</p>
        </div>
      </div>
      <div
        v-if="dropdowns.guests.open === true"
        ref="guestDropdown"
        class=""
        :class="{
          'w-full h-[19rem] overflow-hidden absolute top-full left-0 mt-3 bg-f-white bo:bg-white hj:bg-f-tertiary border hj:border-white/50 md:border-f-bg/60 rounded-2xl pt-2 pb-3 z-50':
            isWidget,
          'w-full h-full md:h-[19rem] overflow-hidden fixed md:absolute top-0 md:top-full left-0 md:mt-3 bg-f-white bo:bg-white hj:bg-f-tertiary md:border md:hj:border-white/50 md:md:border-f-bg/60 md:rounded-2xl md:pt-2 pb-3 z-50':
            notIsWidget,
        }">
        <div class="p-4 flex flex-col gap-4">
          <div class="flex items-start justify-between" :class="{ hidden: isWidget, 'md:hidden': notIsWidget }">
            <p class="bo:font-graphik-semibold hj:font-druk-heavy-italic text-lg">How many guests?</p>
            <img
              v-if="darkMode || $page.props.brand.code === 'hj'"
              :src="asset('assets/close-white.svg')"
              alt="close icon"
              class="w-4 cursor-pointer"
              @click="dropdowns.guests.open = false" />
            <img
              v-else
              :src="asset('assets/close-dark.svg')"
              alt="close icon"
              class="w-4 cursor-pointer"
              @click="dropdowns.guests.open = false" />
          </div>
          <div class="top-0">
            <BengieInput
              type="text"
              v-model="guestsSearch"
              placeholder="Search"
              class="w-full"
              :class="{ 'w-[97%]': isWidget, 'w-[98%] md:w-[99%]': notIsWidget }" />
          </div>
        </div>
        <div
          class=""
          :class="{
            'w-full h-[19rem] overflow-y-scroll custom-scrollbar px-0': isWidget,
            'w-full h-full md:h-[19rem] overflow-y-scroll custom-scrollbar px-6 md:px-0': notIsWidget,
          }">
          <div
            v-for="guest in guestsRange.filter((g) => g.toString().startsWith(guestsSearch))"
            class="flex items-center justify-start py-3 rounded-md justify-center text-f-bg hover:bg-f-contrast hover:text-f-white bo:text-f-tertiary hover:bo:bg-f-tertiary/[.07] hover:cursor-pointer hj:text-f-white"
            :class="{ 'hj:bg-f-contrast': searchParams.guests === guest, 'px-6': isWidget, 'md:px-6': notIsWidget }"
            @click="setGuests(guest)">
            <p>{{ guest }}</p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="dropdowns.dateTime.show" class="w-full mx-2 mb-4 md:mb-0 relative" :class="fieldWidth">
      <div
        class="hj:bg-f-tertiary bg-f-tertiary/[.07] rounded-xl pt-2 pb-2 border border-bg-f-tertiary/[.07] bo:bg-white hj:border-f-tertiary hover:border-f-contrast hover:hj:border-white">
        <p class="text-xs mb-2.5 px-6">Date & Time</p>
        <div
          ref="dateTimeField"
          class="flex items-center justify-start px-6 hover:cursor-pointer"
          @click="toggleDropDown('dateTime')">
          <img v-if="darkMode" :src="asset('assets/calendar.svg')" alt="Calendar icon" class="w-4 mr-3" />
          <img v-else :src="asset('assets/calendar-dark.svg')" alt="Calendar icon" class="w-4 mr-3" />
          <p>{{ searchParams.when.display ?? "Any Time" }}</p>
        </div>
      </div>
      <div
        v-show="dropdowns.dateTime.open === true"
        ref="dateTimeDropdown"
        class=""
        :class="{
          'h-auto w-full md:w-[130%] lg:w-[125%] xl:w-[75%] 2xl:w-[60%] absolute top-full absolute-center-x left-0 mt-3 bg-f-white bo:bg-white hj:bg-f-tertiary border border-f-bg/60 hj:border-white/50 rounded-2xl p-3 z-50 b-hide-date':
            isWidget,
          'w-full h-full md:h-auto md:w-auto md:max-w-[465px] fixed md:absolute top-0 md:top-full md:absolute-center-x left-0 md:mt-3 bg-f-white bo:bg-white hj:bg-f-tertiary md:border md:border-f-bg/60 md:hj:border-white/50 md:rounded-2xl pt-10 md:py-4 px-6 z-50 b-hide-date':
            notIsWidget,
        }">
        <div class="flex items-start justify-between mb-4" :class="{ hidden: isWidget, 'md:hidden': notIsWidget }">
          <p class="bo:font-graphik-semibold hj:font-druk-heavy-italic text-lg">What date and time?</p>
          <img
            v-if="darkMode || $page.props.brand.code === 'hj'"
            :src="asset('assets/close-white.svg')"
            alt="close icon"
            class="w-4 cursor-pointer"
            @click="dropdowns.dateTime.open = false" />
          <img
            v-else
            src="/assets/close-dark.svg"
            alt="close icon"
            class="w-4 cursor-pointer"
            @click="dropdowns.dateTime.open = false" />
        </div>
        <DateTimePicker
          :date-from="formData.from_date"
          :date-to="formData.to_date"
          :open-times="formData.open_times"
          :blocked-dates="formData.blocked_dates"
          @date-selected="setDate"
          @time-selected="setTime"
          @day-selected="setDay"
          @month-selected="setMonth"
          @mode-selected="setMode"
          @close-picker="toggleDropDown('dateTime')"
          :show-flexible="formData.hide_flexible !== true"
          :is-widget="isWidget"
          :calendar-mode="calendarMode" />
      </div>
    </div>
    <div v-if="dropdowns.activity.show" class="w-full pt-2 pb-2 mx-2 mb-4 md:mb-0 relative" :class="fieldWidth">
      <div
        class="hj:bg-f-tertiary bg-f-tertiary/[.07] rounded-xl pt-2 pb-2 border border-bg-f-tertiary/[.07] bo:bg-white hj:border-f-tertiary hover:border-f-contrast hover:hj:border-white">
        <p class="text-xs mb-2.5 px-6">Activity</p>
        <div
          ref="activityField"
          class="flex items-center justify-start px-6 hover:cursor-pointer"
          @click="toggleDropDown('activity')">
          <img v-if="darkMode" :src="asset('assets/face.svg')" alt="Smiley face icon" class="w-4 mr-3" />
          <img v-else :src="asset('assets/face-dark.svg')" alt="Smiley face icon" class="w-4 mr-3" />
          <p class="whitespace-nowrap">{{ searchParams.activity.name }}</p>
        </div>
      </div>
      <div
        v-if="dropdowns.activity.open === true"
        ref="activityDropdown"
        class=""
        :class="{
          'w-full h-auto absolute top-full left-0 mt-3 bg-f-white bo:bg-white hj:bg-f-tertiary border border-f-bg/60 hj:border-white/50 rounded-2xl pt-2 pb-3 px-0 z-50':
            isWidget,
          'w-full h-full md:h-auto fixed  md:absolute top-0 md:top-full left-0 md:mt-3 bg-f-white bo:bg-white hj:bg-f-tertiary md:border md:border-f-bg/60 md:hj:border-white/50 md:rounded-2xl pt-10 md:pt-2 pb-3 px-6 md:px-0 z-50':
            notIsWidget,
        }">
        <div class="flex md:hidden items-start justify-between mb-4">
          <p class="bo:font-graphik-semibold hj:font-druk-heavy-italic text-lg">Which activity?</p>
          <img
            v-if="darkMode || $page.props.brand.code === 'hj'"
            src="/assets/close-white.svg"
            alt="close icon"
            class="w-4 cursor-pointer"
            @click="dropdowns.activity.open = false" />
          <img
            v-else
            src="/assets/close-dark.svg"
            alt="close icon"
            class="w-4 cursor-pointer"
            @click="dropdowns.activity.open = false" />
        </div>
        <div
          v-for="activity in activities.sort((a, b) => a.sequence - b.sequence)"
          :key="activity.id"
          class="flex items-center justify-start py-3 md:px-6 text-f-bg hover:bg-f-contrast hover:text-f-white bo:text-f-tertiary hover:bo:bg-f-tertiary/[.07] hover:cursor-pointer"
          @click="selectActivity(activity.id)">
          <p>{{ activity.name }}</p>
        </div>
        <div
          v-if="!formData.hide_flexible"
          class="flex items-center justify-start py-3 md:px-6 text-f-bg hover:bg-f-contrast hover:text-f-white bo:text-f-tertiary hover:bo:bg-f-tertiary/[.07] hover:cursor-pointer border-t border-t-f-bg/60 hj:border-t-white/40"
          @click="selectActivity(null)">
          <p>I'm flexible</p>
        </div>
      </div>
    </div>
    <div class="w-full md:w-auto md:flex md:self-stretch md:mx-2" :class="searchButtonPadding">
      <LoadingButton
        id="searchButton"
        text="Search"
        loading-text="Searching"
        :loading="loading"
        class="w-full md:w-auto font-bold hj:font-normal rounded-full hj:rounded-xl bg-f-contrast py-3 md:py-1 px-6 text-f-tertiary hj:text-f-white hover:text-f-white cursor-pointer hover:bg-f-tertiary hover:hj:bg-f-secondary hover:hj:bg-f-primary"
        @button-click="submit" />
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon"
import DateTimePicker from "@common/components/DateTimePicker.vue"
import LoadingButton from "../components/LoadingButton.vue"
import route from "ziggy"
import BengieInput from "@common/components/forms/BengieInput.vue"

export default {
  name: "SearchBar",
  components: {
    BengieInput,
    LoadingButton,
    DateTimePicker,
  },
  props: {
    venues: {
      type: Array,
      required: true,
    },
    activities: {
      type: Array,
      required: true,
    },
    reservationId: {
      type: String,
    },
    redirectOnSearch: {
      type: Boolean,
      default: false,
    },
    isWidget: {
      type: Boolean,
      default: false,
    },
    code: {
      type: String,
    },
    calendarMode: {
      type: String,
    },
  },
  emits: ["searchFinished"],
  data() {
    return {
      loading: false,
      timeSelected: false,
      guestsSearch: "",
      // statuses: {
      //   interacted: this.$page.props.booking.reservation.status !== 'created',
      //   searched: !['created', 'interacted'].includes(this.$page.props.booking.reservation.status)
      // },
      dropdowns: {
        venue: {
          show: true,
          open: false,
          default: {
            id: this.venues[0].id,
            name: this.venues[0].name,
          },
        },
        guests: {
          show: true,
          open: false,
          default: 2,
        },
        dateTime: {
          show: true,
          open: false,
          default: {
            value: DateTime.now().set({ hour: 19, minute: 0 }).toFormat("yyyy-MM-dd HH:mm"),
            display: DateTime.now().set({ hour: 19, minute: 0 }).toFormat("EEE, dd MMM - HH:mm"),
          },
        },
        activity: {
          show: true,
          open: false,
          default: null,
        },
      },
    }
  },
  computed: {
    notIsWidget() {
      return this.isWidget === false
    },
    fieldWidth() {
      const fieldsDisplayed = this.numberOfFieldsDisplayed()
      return fieldsDisplayed === 1 ? "md:w-full" : `md:w-1/${fieldsDisplayed}`
    },
    searchButtonPadding() {
      if (this.numberOfFieldsDisplayed()) {
        return "my-2"
      }
      return "my-0"
    },
    parent() {
      return this.$parent.$parent.$options.name
    },
    formData() {
      return this.$store.state.searchFormData
    },
    guestsRange() {
      const guests = []
      for (let i = 0; i <= this.formData.to_guests; i++) {
        if (i >= this.formData.from_guests) {
          guests.push(i)
        }
      }
      return guests
    },
    searchParams() {
      const hasNullVenueObject = Object.values(this.$store.state.searchParams?.venue || {}).includes(null)
      if (this.$store.state.searchParams === null || hasNullVenueObject) {
        const { id, name, slug } = this.getDefaultVenue()
        this.$store.dispatch("updateSearchParams", {
          venue: {
            id,
            name,
            slug,
          },
          when: {
            value: null,
            display: "Any Time",
            mode: "date",
            day: null,
            month: null,
          },
          guests: this.getDefaultGuests(),
          activity: {
            id: this.activities.length > 1 && !this.formData.hide_flexible ? null : this.activities[0].id,
            name: this.activities.length > 1 && !this.formData.hide_flexible ? "I'm flexible" : this.activities[0].name,
            slug: this.activities.length > 1 && !this.formData.hide_flexible ? null : this.activities[0].slug,
          },
        })
      }
      return this.$store.state.searchParams
    },
    searchParamsForCurrentSearch() {
      const hasNullVenueObject = Object.values(this.$store.state.searchParamsForCurrentSearch?.venue || {}).includes(
        null,
      )
      if (this.$store.state.searchParamsForCurrentSearch === null || hasNullVenueObject) {
        const { id, name, slug } = this.getDefaultVenue()
        this.$store.dispatch("updateSearchParamsForCurrentSearch", {
          venue: {
            id,
            name,
            slug,
          },
          when: {
            value: null,
            display: "Any Time",
            mode: "date",
            day: null,
            month: null,
          },
          guests: this.getDefaultGuests(),
          activity: {
            id: this.activities.length > 1 && !this.formData.hide_flexible ? null : this.activities[0].id,
            name: this.activities.length > 1 && !this.formData.hide_flexible ? "I'm flexible" : this.activities[0].name,
            slug: this.activities.length > 1 && !this.formData.hide_flexible ? null : this.activities[0].slug,
          },
        })
      }
      return this.$store.state.searchParamsForCurrentSearch
    },
    searchFields() {
      return {
        venue_id: this.searchParams.venue.id,
        activity_id: this.searchParams.activity.id ?? null,
        mode: this.searchParams.when.mode,
        date:
          this.searchParams.when.value !== null
            ? this.getDateTime(this.searchParams.when.value, "yyyy-MM-dd HH:mm").toFormat("yyyy-MM-dd")
            : null,
        time:
          this.searchParams.when.value !== null
            ? this.getDateTime(this.searchParams.when.value, "yyyy-MM-dd HH:mm").toFormat("HH:mm")
            : null,
        day: this.searchParams.when.day,
        month: this.searchParams.when.month,
        guests: this.searchParams.guests,
        find_alternatives: true,
      }
    },
    trackInteractProperties() {
      let time = null
      if (this.timeSelected) {
        if (this.searchParams.when.value !== null) {
          time = this.getDateTime(this.searchParams.when.value, "yyyy-MM-dd HH:mm").toFormat("HH:mm")
        }
      }
      return {
        event: "search",
        properties: {
          location: window.location.href,
          venue: this.searchParams?.venue.name,
          date:
            this.searchParams.when.value !== null
              ? this.getDateTime(this.searchParams.when.value, "yyyy-MM-dd HH:mm").toFormat("yyyy-MM-dd")
              : null,
          time: time,
          guests: this.searchParams.guests,
          activity: this.searchParams?.activity.name,
          next_step: this.$store.state.searchResults?.next_step,
          searchoverride: this.code,
          flexible_date: this.searchParams.when.day,
          flexible_month: this.searchParams.when.month,
        },
        trackingServices: this.trackingGroups.all,
      }
    },
  },
  methods: {
    numberOfFieldsDisplayed() {
      let fieldsDisplayed = 0
      if (this.showField("activity")) {
        fieldsDisplayed++
      }
      if (this.showField("venue")) {
        fieldsDisplayed++
      }
      if (this.showField("guests")) {
        fieldsDisplayed++
      }
      if (this.showField("dateTime")) {
        fieldsDisplayed++
      }
      return fieldsDisplayed
    },
    showField(field) {
      if (field === "venue") {
        return this.venues.length > 1
      }
      if (field === "guests") {
        return this.$store.state.searchFormData.from_guests !== this.$store.state.searchFormData.to_guests
      }
      if (field === "dateTime") {
        return this.$store.state.searchFormData.from_date !== this.$store.state.searchFormData.to_date
      }
      if (field === "activity") {
        return this.activities.length > 1
      }
    },
    setFieldVisibilities() {
      this.dropdowns.venue.show = this.showField("venue")
      this.dropdowns.guests.show = this.showField("guests")
      this.dropdowns.dateTime.show = this.showField("dateTime")
      this.dropdowns.activity.show = this.showField("activity")
    },
    setDefaults() {
      if (this.searchParamsForCurrentSearch !== null) {
        this.setGuests(this.searchParamsForCurrentSearch.guests, false)
      } else {
        this.setGuests(this.getDefaultGuests(), false)
      }
    },
    getDefaultVenue() {
      return this.formData.default_venue?.id
        ? this.venues.find((v) => v.id === this.formData.default_venue.id)
        : this.venues[0]
    },
    getDefaultGuests() {
      return this.guestsRange.includes(this.formData.default_guests)
        ? this.formData.default_guests
        : this.guestsRange[0]
    },
    submit() {
      this.loading = true
      this.$store.dispatch("updateSelectedBookingSlot", null)
      this.$store.dispatch("updateNextStep", true)

      if (this.searchFields.guests < this.formData.self_service_cut_off) {
        if (this.isWidget) {
          this.loading = false
          window.open(
            route(this.getRoute("reservation.search"), {
              reservation: this.reservationId,
              searchOverride: this.formData.code,
              date: this.searchFields.date,
              time: this.searchFields.time,
              day: this.searchFields.day,
              month: this.searchFields.month,
              mode: this.searchFields.mode,
              guests: this.searchFields.guests,
              activity: this.searchParams.activity.slug,
              venue: this.searchParams.venue.slug,
              widget: true,
              gdpr: localStorage.getItem("gdpr_consent"),
            }),
            "_blank",
          )
        } else {
          const reservationRoute = this.$posthog.getFeatureFlag('calendar-loading-skeleton') === 'test' ? 'reservation.fast_search' : 'reservation.search'

          this.$posthog.capture('reservation_searchbar_search_initiated', {
            search_override: this.formData.code,
            search_guests_number: this.searchFields.guests,
            search_date: this.searchFields.date,
            search_time: this.searchFields.time,
            search_mode: this.searchFields.mode,
            search_venue: this.searchParams.venue.name,
            search_activity: this.searchParams.activity.name,
          })

          this.$store.dispatch("updateSearchParamsForCurrentSearch", this.searchParams)
          this.$inertia.visit(
            route(this.getRoute(reservationRoute), {
              reservation: this.reservationId,
              searchOverride: this.formData.code,
              date: this.searchFields.date,
              time: this.searchFields.time,
              day: this.searchFields.day,
              month: this.searchFields.month,
              mode: this.searchFields.mode,
              guests: this.searchFields.guests,
              activity: this.searchParams.activity.slug,
              venue: this.searchParams.venue.slug,
              widget: false,
            })
          )
        }
      } else {
        if (this.isWidget) {
          this.loading = false
          window.open(
            route(this.getRoute("booking.enquire.index"), {
              venue_id: this.searchFields.venue_id,
              date: this.searchFields.date,
              time: this.searchFields.time,
              guests: this.searchFields.guests,
            }),
            "_blank",
          )
        } else {
          this.$inertia.get(
            route(this.getRoute("booking.enquire.index"), {
              venue_id: this.searchFields.venue_id,
              date: this.searchFields.date,
              time: this.searchFields.time,
              guests: this.searchFields.guests,
            }),
          )
        }
      }
    },
    selectVenue(id, toggle = true) {
      this.updateSearchChanged(true)
      const params = this.searchParams
      params.venue.id = this.venues.find((v) => v.id === id).id
      params.venue.name = this.venues.find((v) => v.id === id).name
      params.venue.slug = this.venues.find((v) => v.id === id).slug
      if (toggle) {
        this.toggleDropDown("venue")
      }
      this.updateSearchParams(params)
      this.trackInteract(
        this.trackInteractProperties.event,
        this.trackInteractProperties.properties,
        this.trackInteractProperties.trackingServices,
        this.isWidget,
      )
    },
    selectActivity(id, toggle = true) {
      this.updateSearchChanged(true)
      const params = this.searchParams
      if (id === null) {
        params.activity.id = null
        params.activity.name = "I'm flexible"
      } else {
        params.activity.id = this.activities.find((a) => a.id === id).id
        params.activity.name = this.activities.find((a) => a.id === id).name
        params.activity.slug = this.activities.find((a) => a.id === id).slug
      }
      if (toggle) {
        this.toggleDropDown("activity")
      }
      this.updateSearchParams(params)
      this.trackInteract(
        this.trackInteractProperties.event,
        this.trackInteractProperties.properties,
        this.trackInteractProperties.trackingServices,
        this.isWidget,
      )
    },
    setGuests(guests, toggle = true) {
      this.updateSearchChanged(true)
      const params = this.searchParams
      params.guests = guests
      if (toggle) {
        this.toggleDropDown("guests")
      }
      this.updateSearchParams(params)
      this.guestsSearch = ""
      this.trackInteract(
        this.trackInteractProperties.event,
        this.trackInteractProperties.properties,
        this.trackInteractProperties.trackingServices,
        this.isWidget,
      )
    },
    setDate(date) {
      const params = this.searchParams
      if (!date) {
        params.when.display = "Any time"
      } else {
        this.updateSearchChanged(true)
        const newDate = DateTime.fromJSDate(date)
        const currentDate = params.when.value
          ? DateTime.fromFormat(params.when.value, "yyyy-MM-dd HH:mm")
          : DateTime.now()
        const updatedDate = currentDate.set({ day: newDate.day, month: newDate.month, year: newDate.year })
        if (!this.timeSelected) {
          const now = DateTime.now()
          const remainder = 15 - (now.minute % 15)
          const time = now.plus({ minutes: remainder })
          const defaultTime = this.getDefaultTime()
          if (time < defaultTime) {
            params.when.display = updatedDate
              .set({ minute: defaultTime.minute, hour: defaultTime.hour, second: 0 })
              .toFormat("EEE, dd MMM - HH:mm")
            params.when.value = updatedDate
              .set({ minute: defaultTime.minute, hour: defaultTime.hour, second: 0 })
              .toFormat("yyyy-MM-dd HH:mm")
          } else {
            params.when.display = updatedDate
              .set({ minute: time.minute, hour: time.hour, second: 0 })
              .toFormat("EEE, dd MMM - HH:mm")
            params.when.value = updatedDate
              .set({ minute: time.minute, hour: time.hour, second: 0 })
              .toFormat("yyyy-MM-dd HH:mm")
          }
        } else {
          params.when.display = updatedDate.toFormat("EEE, dd MMM - HH:mm")
          params.when.value = updatedDate.toFormat("yyyy-MM-dd HH:mm")
        }
        this.updateSearchParams(params)
        this.trackInteract(
          this.trackInteractProperties.event,
          this.trackInteractProperties.properties,
          this.trackInteractProperties.trackingServices,
          this.isWidget,
        )
      }
    },
    setTime(time) {
      this.updateSearchChanged(true)
      const params = this.searchParams
      this.timeSelected = true
      const newDate = DateTime.fromFormat(time, "HH:mm")
      const currentDate = params.when.value
        ? DateTime.fromFormat(params.when.value, "yyyy-MM-dd HH:mm")
        : DateTime.now()
      const updatedDate = newDate.set({ day: currentDate.day, month: currentDate.month, year: currentDate.year })
      params.when.value = updatedDate.toFormat("yyyy-MM-dd HH:mm")
      params.when.display = updatedDate.toFormat("EEE, dd MMM - HH:mm")
      this.updateSearchParams(params)
      this.trackInteract(
        this.trackInteractProperties.event,
        this.trackInteractProperties.properties,
        this.trackInteractProperties.trackingServices,
      )
    },
    setDay(day) {
      this.updateSearchChanged(true)
      const params = this.searchParams
      params.when.day = day
      params.when.display =
        this.capitalise(day) +
        " in " +
        (params.when.month ? DateTime.fromISO(params.when.month).toFormat("MMMM") : DateTime.now().toFormat("MMMM"))
      this.updateSearchParams(params)
      this.trackInteract(
        this.trackInteractProperties.event,
        this.trackInteractProperties.properties,
        this.trackInteractProperties.trackingServices,
        this.isWidget,
      )
    },
    setMonth(month) {
      this.updateSearchChanged(true)
      const params = this.searchParams
      params.when.month = month.toFormat("yyyy-MM-dd")
      params.when.display =
        this.capitalise(params.when.day ?? "Weekday") + " in " + DateTime.fromISO(month).toFormat("MMMM")
      this.updateSearchParams(params)
      this.trackInteract(
        this.trackInteractProperties.event,
        this.trackInteractProperties.properties,
        this.trackInteractProperties.trackingServices,
        this.isWidget,
      )
    },
    setMode(mode) {
      this.updateSearchChanged(true)
      const params = this.searchParams
      params.when.mode = mode
      if (mode === "date") {
        params.when.day = null
        params.when.month = null
      }
      if (mode === "flexible") {
        params.when.value = null
      }
      this.updateSearchParams(params)
      this.trackInteract(
        this.trackInteractProperties.event,
        this.trackInteractProperties.properties,
        this.trackInteractProperties.trackingServices,
        this.isWidget,
      )
    },
    toggleDropDown(name) {
      this.dropdowns[name].open = !this.dropdowns[name].open
      // if(!this.statuses.interacted) {
      //   this.updateReservationStatus(this.$page.props.booking.reservation, 'interacted', () => this.statuses.interacted = true)
      // }
    },
    closeDropdownOnOutsideClick(event) {
      for (const ref in this.$refs) {
        if (ref === null || ref === "undefined") {
          return
        }
      }
      if (this.dropdowns.venue.open) {
        if (
          this.$refs.venueDropdown !== event.target &&
          !this.$refs.venueDropdown.contains(event.target) &&
          this.$refs.venueField !== event.target &&
          !this.$refs.venueField.contains(event.target)
        ) {
          this.dropdowns.venue.open = false
        }
      }
      if (this.dropdowns.guests.open) {
        if (
          this.$refs.guestDropdown !== event.target &&
          !this.$refs.guestDropdown.contains(event.target) &&
          this.$refs.guestField !== event.target &&
          !this.$refs.guestField.contains(event.target)
        ) {
          this.dropdowns.guests.open = false
        }
      }
      if (this.dropdowns.dateTime.open) {
        if (
          this.$refs.dateTimeDropdown !== event.target &&
          !this.$refs.dateTimeDropdown.contains(event.target) &&
          this.$refs.dateTimeField !== event.target &&
          !this.$refs.dateTimeField.contains(event.target)
        ) {
          this.dropdowns.dateTime.open = false
        }
      }
      if (this.dropdowns.activity.open) {
        if (
          this.$refs.activityDropdown !== event.target &&
          !this.$refs.activityDropdown.contains(event.target) &&
          this.$refs.activityField !== event.target &&
          !this.$refs.activityField.contains(event.target)
        ) {
          this.dropdowns.activity.open = false
        }
      }
    },
    updateSearchParams(params) {
      this.$store.dispatch("updateSearchParams", params)
    },
    updateSearchParamsForCurrentSearch(params) {
      this.$store.dispatch("updateSearchParamsForCurrentSearch", params)
    },
    updateSearchChanged(changed) {
      this.$store.dispatch("updateSearchChanged", changed)
    },
    checkIfSearchOverrideIsValidOnThisDate() {
      const formData = this.$store.state.searchFormData
      if (!formData.code) {
        return true
      }
      if (!formData.valid_from && !formData.valid_to) {
        return true
      }
      const validFrom = DateTime.fromFormat(formData.valid_from, "yyyy-MM-dd")
      const validTo = DateTime.fromFormat(formData.valid_to, "yyyy-MM-dd")
      const today = DateTime.now()
      if (today >= validFrom && today <= validTo) {
        return true
      }
      return false
    },
    closeDropdownsFromParent(event) {
      if (event.origin === this.$page.props.brand.website && event.data.name === "blur") {
        if (this.dropdowns.venue.open) {
          this.dropdowns.venue.open = false
        }
        if (this.dropdowns.guests.open) {
          this.dropdowns.guests.open = false
        }
        if (this.dropdowns.dateTime.open) {
          this.dropdowns.dateTime.open = false
        }
        if (this.dropdowns.activity.open) {
          this.dropdowns.activity.open = false
        }
      }
    },
    getDefaultTime() {
      if (this.formData.default_time) {
        return DateTime.fromFormat(this.formData.default_time, "HH:mm")
      }
      return DateTime.now().set({ hour: 18, minute: 15, second: 0 })
    },
  },
  mounted() {
    this.setFieldVisibilities()
    this.setDefaults()
    if (this.$store.state.searchResults) {
      this.trackComplete(
        this.trackInteractProperties.event,
        this.trackInteractProperties.properties,
        this.trackingGroups.all,
        this.isWidget,
      )
    }
    if (!this.checkIfSearchOverrideIsValidOnThisDate()) {
      this.$inertia.get(
        route(this.getRoute("bookings.index")),
        {},
        {
          onSuccess: (page) => {
            this.$page.props.errors["error"] = "This search override is is not valid for the current date"
          },
        },
      )
    }
    if (this.searchFields.time !== null) {
      this.timeSelected = true
    }
  },
  created() {
    document.addEventListener("click", (e) => {
      this.closeDropdownOnOutsideClick(e)
    })
    if (this.isWidget) {
      window.addEventListener("message", (e) => {
        this.closeDropdownsFromParent(e)
      })
    }
  },
  beforeUnmount() {
    document.removeEventListener("click", (e) => {
      this.closeDropdownOnOutsideClick(e)
    })
    window.removeEventListener("message", (e) => {
      this.closeDropdownsFromParent(e)
    })
  },
}
</script>

<style scoped></style>
